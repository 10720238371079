
import { PropType } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import MarkdownRenderer from '@/components/analytics/yandexGPT/MarkdownRenderer.vue';
import { V1EntitiesYandexGptWeakness } from '@/services/api/tsxass';

interface GptResponse {
  name: string,
  content: string,
  error?: string,
  parsed?: string,
}

export default {
  name: 'PdfGptRenderer',
  components: {
    PdfPage,
    MarkdownRenderer,
  },
  props: {
    startPageNumber: {
      type: Number,
      required: true,
    },
    renderStartPageNumber: {
      type: Number,
      default: 0,
    },
    pagesCount: {
      type: Number,
      default: 0,
    },
    surveyName: {
      type: String,
      default: '',
    },
    surveyeeName: {
      type: String,
      default: '',
    },
    gptResponse: {
      type: Object as PropType<V1EntitiesYandexGptWeakness>,
      required: true,
    },
  },
  data() {
    return {
      parsedGptResponse: [] as GptResponse[],
      pages: [] as GptResponse[],
    };
  },
  watch: {
    async pages(newValue: GptResponse[]) {
      await this.$nextTick();
      const result = newValue.map((v: GptResponse) => v.content);
      this.$emit('success', result);
    },
  },

  mounted() {
    this.parsedGptResponse = this.gptResponse?.response?.filter((r) => !r.error && r.content) as GptResponse[] || [];
  },

  methods: {
    async onMarkdownParse(idx: number, parsedValue: string) {
      this.parsedGptResponse[idx].parsed = parsedValue;
      this.parsedGptResponse = [...this.parsedGptResponse];
      if (
        this.parsedGptResponse.filter((r: GptResponse) => r.parsed !== undefined).length
        === this.parsedGptResponse.length
      ) {
        // все разделы успешно спарсились из MD в HTML
        await this.$nextTick();
        // даём им отрисоваться
        const parsedContainers = [...this.$el.querySelectorAll('.gpt-parsed-container')];
        // собираем все конейнеры и пропускаем их через мясорубку (разбиваем каждый из них на страницы)
        this.pages = parsedContainers.map(
          (el: Element, elIdx: number) => this.splitParsedContainerIntoPages(el, this.parsedGptResponse[elIdx].name),
        ).flat();
      }
    },
    splitParsedContainerIntoPages(container: Element, name: string) {
      /**
       * Разбиваем данный контейнер на страницы:
       * 1. Анализируем высоту каждого блока
       * 2. Перебираем их по-очереди, складывая их высоты
       * 3. Если какой-то не влезает, переносим его и следующие на следующую страницу
       * 4. Если не влезающий блок - таблица или список, пытаемся разбить его по подэлементам,
       *    чтобы влезла хотя бы часть
       * 5. Если блок не влезает, и он единственный на странице - забиваем болт
       *    и оставляем его на этой стрнице (пусть не влезает)
       */
      const pages: GptResponse[] = [];
      // считаем все блоки неразобранными
      let hiddenItems = Array.from(container.childNodes) as Element[];
      // высота контейнера, где отрендерелись блоки - это и будет высота страницы
      const containerHeight = container.getBoundingClientRect().height;
      // пока есть неразобранные блоки
      while (hiddenItems.length) {
        // определяем, какие из них влезли на страницу (visible), а какие ещё нет (hidden)
        const items = this.splitElementsIntoArraysByVisibility(hiddenItems, containerHeight);
        hiddenItems = items.hidden;
        // все попавшие на текущую страницу блоки
        // снова превращаем в разметку и запоминаем для дальнейшего рендера
        pages.push({
          name,
          content: items.visible.map((item: Element) => item.outerHTML).join(''),
        });
      }
      return pages;
    },
    splitElementsIntoArraysByVisibility(elements: Element[], containerHeight: number, mainFlow: boolean = true) {
      // если что-то пошло не так
      if (!elements.length) {
        return {
          visible: [],
          hidden: [],
        };
      }
      // определяем начало текущего элемента (это и будет начало страницы)
      const offset = (elements[0] as Element).getBoundingClientRect().top;
      // находим последний элемент, который всё ещё полностью влезает в размер страницы
      const splitIdx = this.getLastVisibleChildIdx(elements, containerHeight + offset);
      // находим предыдущий элемент и определяем, не заголовок ли он
      const prevElement = elements[splitIdx];
      let prevHeaderElement = null as Element | null;
      if (prevElement) {
        const { nodeName } = prevElement;
        const content = prevElement.textContent || '';
        prevHeaderElement = nodeName === 'H1'
        || nodeName === 'H2'
        || nodeName === 'H3'
        || nodeName === 'H4'
        || nodeName === 'H5'
        || nodeName === 'H6'
        || (nodeName === 'P' && content[content.length - 1] === ':')
          ? prevElement : null;
      }
      // формируем списки элементов попавших на страницу и не попавших
      const visibleItems = elements.slice(0, splitIdx + 1);
      const hiddenItems = elements.slice(splitIdx + 2);
      // определяем элемент, который не полностью влезает на страницу: splitChild
      const splitChild = elements[splitIdx + 1];
      if (!splitChild) {
        // если такого элемента splitChild нет, значит все элементы влезли на страницу, ура!
      } else if (splitChild.nodeName === 'TABLE') {
        // если элемент splitChild - таблица, пробуем разбить таблицу по строчкам
        let moveLastVisibleItemToHidden = false;
        const table = splitChild as HTMLTableElement;
        // определяем сколько в ней строчек
        const bodyRows = Array.from(table.tBodies)
          .map((tBody) => Array.from(tBody.rows))
          .flat();
        if (bodyRows.length === 0) {
          // таблица пустая, что-то пошло не так, не рисуем её вообще
          return {
            visible: visibleItems,
            hidden: hiddenItems,
          };
        }
        // смотрим, сколько остаётся места для отрисовки строчек таблицы
        const rowsTop = bodyRows[0].getBoundingClientRect().top;
        const freeSpaceLeft = containerHeight + offset - rowsTop;
        // применяем эту же функцию разбивки элементов по страницам, чтобы
        // определить, сколько строчек таблицы влезает на эту же страницу
        const splitRows = this.splitElementsIntoArraysByVisibility(bodyRows, freeSpaceLeft, false);
        if (!splitRows.visible.length) {
          // если ни одна строчка не влезла на оставшееся место
          if (splitIdx === -1) {
            // если при этом сама таблица -
            // первая на странице (значит первая же строчка - супер высокая, выше страницы)
            // то плюём на неё и будем рендерить как есть на этой же странице, чего уж теперь
            splitRows.visible = [splitRows.hidden.shift() as HTMLTableRowElement];
          }
          if (splitIdx > 0) {
            // похоже, надо переносить всю таблицу на новую страницу, но
            // последний оставшийся  элемент на текущей странице - заголовок (и он не первый!), значит
            // его тоже надо перетащить его на новую страницу, так как он скорее всего относится к
            // этой таблице и бросать его одного нехорошо
            moveLastVisibleItemToHidden = true;
          }
        }
        if (splitRows.visible.length) {
          // если некороые строчки всё-таки поместились на текщую страницу
          // то создаём клон таблицы и
          // переносим в неё заголовок и все отрисованные строчки
          // и считаем всё это последними видимыми блоками на текущей странице
          // а оригинальную таблицу (уже без видимых строчек) отправляем на дальнейший анализ
          const tableClone = table.cloneNode() as HTMLTableElement;
          const tBodyClone = document.createElement('tbody');
          if (table.tHead) {
            tableClone.appendChild(table.tHead.cloneNode(true));
          }
          tBodyClone.append(...splitRows.visible);
          tableClone.appendChild(tBodyClone);
          visibleItems.push(tableClone);
        }
        hiddenItems.unshift(table);
        if (prevHeaderElement && moveLastVisibleItemToHidden) {
          visibleItems.pop();
          hiddenItems.unshift(prevHeaderElement);
        }
      } else if (splitChild.nodeName === 'OL' || splitChild?.nodeName === 'UL') {
        // то же самое, что делали с таблицей, делаем со списками
        // за тем исключением, что анализируем не TR, а LI
        // так же тут нет заголовков THEAD
        // а ещё тут надо оставить в оригинальном списке клоны удалённых LI, чтобы
        // не нарушались порядковые номера в случае OL
        let moveLastVisibleItemToHidden = false;
        const list = splitChild as HTMLOListElement | HTMLUListElement;
        const listItems = Array.from(list.children) as Element[];
        if (listItems.length === 0) {
          return {
            visible: visibleItems,
            hidden: hiddenItems,
          };
        }
        const itemsTop = listItems[0].getBoundingClientRect().top;
        const freeSpaceLeft = containerHeight + offset - itemsTop;
        const splitItems = this.splitElementsIntoArraysByVisibility(listItems, freeSpaceLeft, false);
        if (!splitItems.visible.length) {
          if (splitIdx === -1) {
            splitItems.visible = [splitItems.hidden.shift() as HTMLTableRowElement];
          }
          if (splitIdx > 0) {
            moveLastVisibleItemToHidden = true;
          }
        }
        if (splitItems.visible.length) {
          const listClone = list.cloneNode() as HTMLElement;
          listClone.append(...splitItems.visible);
          const clonedHiddenItems = splitItems.visible.map((item) => {
            const clonedItem = item.cloneNode(true) as HTMLElement;
            clonedItem.style.height = '0';
            clonedItem.style.overflow = 'hidden';
            clonedItem.style.visibility = 'hidden';
            return clonedItem;
          });
          list.prepend(...clonedHiddenItems);
          visibleItems.push(listClone);
        }
        hiddenItems.unshift(list);
        if (prevHeaderElement && moveLastVisibleItemToHidden) {
          visibleItems.pop();
          hiddenItems.unshift(prevHeaderElement);
        }
      } else if (splitIdx === -1) {
        // если обычный элемент не влезает на страницу
        if (mainFlow) {
          visibleItems.push(splitChild);
        } else {
          hiddenItems.unshift(splitChild);
        }
      } else {
        hiddenItems.unshift(splitChild);
      }
      return {
        visible: visibleItems,
        hidden: hiddenItems,
      };
    },
    getLastVisibleChildIdx(elements: Element[], bottom: number) {
      // @ts-ignore
      return elements.findLastIndex((child) => {
        if (!child) {
          return false;
        }
        const childRect = child.getBoundingClientRect();
        return childRect.bottom < bottom;
      });
    },
  },
};
