
import Vue from 'vue';
import PdfGptWelcomePageRenderer from '@/components/analytics/pdf/renderers/gpt/PdfGptWelcomePageRenderer.vue';
import PdfGptRenderer from '@/components/analytics/pdf/renderers/gpt/PdfGptRenderer.vue';
import { tsxassApi } from '@/services/api';
import { V1EntitiesYandexGptWeakness, V1EntitiesSurveysPublicShow, V1EntitiesUsersPublicShowUser } from '@/services/api/tsxass';

export default Vue.extend({
  name: 'CreatePdfGpt',
  components: {
    PdfGptWelcomePageRenderer,
    PdfGptRenderer,
  },
  data() {
    return {
      rendererNames: [PdfGptWelcomePageRenderer.name, PdfGptRenderer.name],
      rendererComponentIdx: -1,
      // сохраняем тут картинки локально
      images: [] as string[],
      gptResponse: null as V1EntitiesYandexGptWeakness | null,
      // Данные об опросе
      survey: null as V1EntitiesSurveysPublicShow | null,
      // Данные о сотруднике
      userData: null as V1EntitiesUsersPublicShowUser | null,
    };
  },
  computed: {
    page(): number {
      return Number(this.$route.query.page) || 0;
    },
    pageStartNumber(): number {
      return Number(this.$route.query.pageStartNumber) || 0;
    },
    renderStartPageNumber(): number {
      return Number(this.$route.query.start) || 0;
    },
    renderPagesCount(): number {
      return Number(this.$route.query.count) || 0;
    },
    currentRendererName() {
      return this.rendererNames[this.rendererComponentIdx] || this.rendererNames[0];
    },
    // Значение прогресса выполнения (0 - 100)
    currentProgressValue(): number {
      return Math.round((this.rendererComponentIdx / this.rendererNames.length) * 100);
    },
    paramPdfCompression() {
      return this.$route.query.pdfCompression;
    },
    surveyId() {
      return Number(this.$route.params.surveyId || '-1');
    },
    userId() {
      return ((this.$route.query.userId || '') as string);
    },
    surveyeeName(): string {
      if (!this.userData) {
        return '';
      }

      return `${this.userData.lastName} ${this.userData.firstName}`;
    },
  },
  watch: {
    page: {
      handler() {
        this.rendererComponentIdx = this.page;
      },
      immediate: true,
    },
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('page-start', {
      detail: { pages: this.rendererNames.length },
    }));
    this.loadUserData();
    this.loadSurvey();
    this.fetchGpt();
  },
  methods: {
    async loadUserData() {
      try {
        const { data } = await tsxassApi.getV1UsersId(this.userId);
        this.userData = data?.user || null;
      } catch (err) {
        this.$emit('error');
      }
    },
    async loadSurvey() {
      try {
        const { data: survey } = await tsxassApi.getV1SurveysSurveyId(this.surveyId, this.userId);
        this.survey = survey;
      } catch (err) {
        this.$emit('error');
      }
    },
    async fetchGpt() {
      let response: V1EntitiesYandexGptWeakness | null = null;
      try {
        const { data } = await tsxassApi.postV1YandexGptWeakness({
          surveyId: this.surveyId,
          userId: this.userId,
          newGeneration: false,
          retry: false,
        });
        response = data;
        if (response.status === 'finished') {
          this.gptResponse = response;
        }
      } catch (error) {
        this.$emit('error', error);
      } finally {
        if (response && response.status !== 'finished' && response.status !== 'failure') {
          setTimeout(() => { this.fetchGpt(); }, 3000);
        }
      }
    },
    onRendererSuccess(event: string[]) {
      // картинки могут и не прийти, поэтому надопроверить, есть ли что-то там
      if (event) {
        this.images.push(...event);
      }
      this.$emit('progress', this.currentProgressValue);
      window.dispatchEvent(new CustomEvent('page-ready', {
        detail: {
          nextPageStartNumber: this.pageStartNumber + this.images.length,
          isLastPage: this.page === this.rendererNames.length - 1,
        },
      }));
    },
    onRendererError(err: Error) {
      this.$emit('error', err);
    },
  },
});
