
import { PropType, defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import { V1EntitiesYandexGptWeakness } from '@/services/api/tsxass';

export default defineComponent({
  name: 'PdfGptWelcomePageRenderer',

  components: { PdfPage },

  props: {
    surveyName: {
      type: String,
      default: '',
    },
    surveyeeName: {
      type: String,
      default: '',
    },
    gptResponse: {
      type: Object as PropType<V1EntitiesYandexGptWeakness>,
      default: () => {},
    },
  },
  data() {
    return {
      startAt: '',
      finishAt: '',
    };
  },
  computed: {
    exportDate(): string {
      return this.$dayjs().format('DD.MM.YYYY');
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$emit('success', [this.surveyName]);
  },
});

