<template>
  <VApp>
    <CreatePdfGpt
      @success="isSuccess = true"
      @error="isError = true"
      @progress="progress = $event"
    />
  </VApp>
</template>

<script>
import CreatePdfGpt from '@/components/analytics/pdf/CreatePdfGpt.vue';

export default {
  name: 'ExportPdfGptLayout',
  components: {
    CreatePdfGpt,
  },
  data() {
    return {
      isError: false,
      isSuccess: false,
      progress: 0,
    };
  },
};
</script>
<style lang="scss">
#hde-container {
  display: none !important;
}
</style>
